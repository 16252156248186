<template>
  <button
    class="btn bg-blue rounded-pill text-white ms-2"
    data-bs-toggle="modal"
    :data-bs-target="`#${targetModal}`"
    v-show="false"
    ref="btnShowModal"
  ></button>
  <div
    class="modal fade"
    :id="targetModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header flex-column flex-md-row">
          <div class="d-flex">
            <div class="pt-2 px-3">
              <i class="bi bi-gear-fill fs-3 text-body"></i>
            </div>
            <div>
              <span class="fs-1 fw-bolder">
                {{
                  tipoAjuste === 1
                    ? "Mover Ubicación"
                    : "Cambio nombre dispositivo"
                }}
              </span>
            </div>
          </div>
          <div class="align-items-end">
            <img
              alt="Logo"
              src="/media/logos/logo_sens.svg"
              style="width: 30px"
            />
            <span class="fs-3 fw-bolder pe-2">{{ datosDispo.nombre }}</span>
            <span class="fs-4 text-muted fw-light">{{
              datosDispo.areaNombre
            }}</span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <el-form
            ref="temAjustesForm"
            :model="model"
            :rules="formRules"
            class="demo-ruleForm"
          >
            <div class="card">
              <div class="card-body">
                <div class="row" v-if="tipoAjuste === 1">
                  <div class="col-md-3 col-sm-12">
                    <div class="p-2">
                      <el-form-item prop="departamentoId">
                        <select
                          @change="selectedCiudad"
                          class="form-select"
                          aria-label="Default select example"
                          id="departamentoId"
                          v-model="model.departamentoId"
                          name="departamentoId"
                        >
                          <option disabled value="">Departamento</option>
                          <option
                            v-for="(departamento, i) in departamentos"
                            :key="i"
                            :value="departamento.id"
                          >
                            {{ departamento.name }}
                          </option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="p-2">
                      <el-form-item prop="ciudadId">
                        <select
                          @change="selectedCiudad"
                          class="form-select"
                          aria-label="Default select example"
                          v-model="model.ciudadId"
                          id="ciudadId"
                          name="ciudadId"
                        >
                          <option disabled value="">Ciudad</option>
                          <option
                            v-for="(ciudad, e) in ciudades"
                            :key="e"
                            :value="ciudad.id"
                          >
                            <p class="text-dark">
                              {{ ciudad.name }}
                            </p>
                          </option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="p-2">
                      <el-form-item prop="ubicacionSel">
                        <select
                          @change="selectedSede"
                          class="form-select"
                          aria-label="Default select example"
                          v-model="model.ubicacionSel"
                          id="ubicacionSel"
                          name="ubicacionSel"
                        >
                          <option
                            v-for="(ubicacion, e) in ubicaciones"
                            :key="e"
                            :value="ubicacion._id"
                          >
                            <p class="text-dark">
                              {{ ubicacion.nombre }}
                            </p>
                          </option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <div class="p-2">
                      <el-form-item prop="areaId">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="model.areaId"
                          id="areaId"
                          name="areaId"
                        >
                          <option
                            v-for="(area, e) in areas"
                            :key="e"
                            :value="area._id"
                          >
                            <p class="text-dark">
                              {{ area.nombre }}
                            </p>
                          </option>
                        </select>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="tipoAjuste === 2">
                  <div class="col-md-12">
                    <el-form-item prop="nombre" label="Nombre dispositivo">
                      <el-input v-model="model.nombre" />
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
          <div class="row justify-content-center mt-5">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              ref="btnCloseModal"
              v-show="false"
            >
              Close
            </button>
            <div class="col-md-4 text-end">
              <button
                @click="onSubmit"
                name="button"
                class="btn bg-blue text-white btn-sm w-100"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, reactive, watch } from "vue";
import { alerta } from "@/utils/mensajes";
import { editarDispositivo } from "@/services/dispositivos";
import sedes from "@/services/sedes.service";

export default {
  name: "ajusteDispositivoModal",
  emits: ["responseSuccess"],
  props: {
    targetModal: {
      type: String,
      default: "rangoSensoresModal",
    },
  },
  setup(props, { emit }) {
    const tipoAjuste = ref(1);
    const departamentos = ref([]);
    const ciudades = ref([]);
    const ubicaciones = ref([]);
    const areas = ref([]);
    const datosDispo = ref({
      nombre: "",
      areaNombre: "",
    });
    const temAjustesForm = ref();
    const btnShowModal = ref();
    const btnCloseModal = ref();
    const model = reactive({
      _id: "",
      departamentoId: "",
      ciudadId: "",
      ubicacionSel: "",
      areaId: "",
      nombre: "",
    });
    const formRules = reactive({
      departamentoId: [
        {
          required: true,
          message: "departamento es requerido",
          trigger: "blur",
        },
      ],
      ciudadId: [
        {
          required: true,
          message: "ciudad es requerido",
          trigger: "blur",
        },
      ],
      ubicacionSel: [
        {
          required: true,
          message: "ubicacion es requerido",
          trigger: "blur",
        },
      ],
      areaId: [
        {
          required: true,
          message: "área es requerido",
          trigger: "blur",
        },
      ],
      nombre: [
        {
          required: true,
          message: "nombre dispositivo es requerido",
          trigger: "blur",
        },
      ],
    });

    const resetForm = () => {
      model.value = {
        _id: "",
        departamentoId: "",
        ciudadId: "",
        ubicacionSel: "",
        areaId: "",
      };
    };
    const onSubmit = async () => {
      await temAjustesForm.value.validate(async (valid) => {
        console.log(valid, "validdddd");
        if (!valid) return false;
        const payload = {
          ubicacionSel: model.ubicacionSel,
          areaId: model.areaId,
          nombre: model.nombre,
          tipo: tipoAjuste.value,
        };
        const { data } = await editarDispositivo(model._id, payload).catch(
          (error) => {
            console.log(error);
            btnCloseModal.value.click();
          }
        );
        if (data) {
          alerta(
            "Mensaje",
            data.message ? data.message : "Editado correctamente",
            "success"
          );
          resetForm();
          emit("responseSuccess");
          btnCloseModal.value.click();
        }
      });
    };
    const traerDepartamentos = () => {
      sedes.listarDepartamentos("61a7b63262c066e408abe8c9").then((data) => {
        departamentos.value = data.data;
      });
    };
    const traerCiudades = (id) => {
      ubicaciones.value = [];
      areas.value = [];
      id = model.departamentoId;
      return sedes.listarCiudades(id).then((data) => {
        ciudades.value = data.data;
        return data.data;
      });
    };
    const traerSedes = (id) => {
      areas.value = [];
      id = model.ciudadId;
      return sedes.listarSedesPorCiudades(id).then(({ data }) => {
        ubicaciones.value = data;
        return data;
      });
    };
    const traerAreas = (sedeId) => {
      return sedes
        .listarAreas(sedeId)
        .then(({ data }) => {
          areas.value = data.areas;
          return data.areas;
        })
        .catch((err) => {
          console.error(err.message);
        });
    };
    const toggle = (data, tipo) => {
      console.log(data);
      resetForm();
      tipoAjuste.value = tipo;
      model._id = data._id;
      datosDispo.value.nombre = data.nombre;
      datosDispo.value.areaNombre = data.area.nombre;
      model.nombre = data.nombre;
      model.departamentoId = data.departamentoId;
      model.ciudadId = data.sede.ciudadId;
      model.ubicacionSel = data.sede._id;
      model.areaId = data.areaId;
      traerCiudades().then(() => {
        traerSedes().then(() => {
          traerAreas(model.ubicacionSel);
        });
      });

      btnShowModal.value.click();
    };

    onMounted(() => {
      traerDepartamentos();
    });

    const selectedDpto = () => {
      model.ciudadId = "";
      if (model.departamentoId) {
        traerCiudades();
      }
    };

    const selectedCiudad = () => {
      model.ubicacionSel = "";
      if (model.ciudadId) {
        traerSedes();
      }
    };

    const selectedSede = () => {
      model.areaId = "";
      if (model.ubicacionSel) {
        traerAreas(model.ubicacionSel);
      }
    };

    return {
      btnShowModal,
      btnCloseModal,
      model,
      datosDispo,
      formRules,
      temAjustesForm,
      departamentos,
      ciudades,
      ubicaciones,
      areas,
      tipoAjuste,
      toggle,
      onSubmit,
      selectedSede,
      selectedCiudad,
      selectedDpto,
    };
  },
};
</script>
<style scoped>
.texto-valor {
  font-size: 40px;
}
.modal-dialog {
  max-width: 990px;
}
.modal-content {
  border-radius: 1rem;
}
.modal-header {
  border-radius: 1rem;
  padding: 1rem 1rem;
  background-image: url("/img/fondo-header-histo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: none;
}
.texto-paginator {
  padding-left: 15rem;
}
.el-form-item {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .card-body {
    padding: 1em 1em;
  }
  .texto-paginator {
    padding-left: 0rem;
  }
}
</style>
