<template>
  <!--begin::Toolbar-->
  <div class="d-flex flex-wrap flex-stack">
    <!--begin::Heading-->
    <h2 class="card-title align-items-start flex-column">
      <span class="card-label fw-bolder text-blue">Mis ubicaciones</span>
    </h2>
    <div class="col-md-4 col-sm-12">
      <router-link
        to="equipos/ubicacion/crear"
        class="btn d-flex align-items-center justify-content-end"
      >
        <i
          class="bi bi-plus-lg text-white p-2 fw-bold bg-blue rounded-circle"
        ></i>
        <p style="margin-left: 5px !important">Agregar ubicación</p>
      </router-link>
    </div>
    <!--end::Heading-->
  </div>
  <div class="row">
    <div class="col-lg-9 col-sm-12">
      <div class="card">
        <div class="card-body p-3">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="p-2">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  id="departamentoId"
                  v-model="departamentoId"
                  name="departamentoId"
                >
                  <option disabled value="">Departamento</option>
                  <option
                    v-for="(departamento, i) in departamentos"
                    :key="i"
                    :value="departamento.id"
                  >
                    {{ departamento.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="p-2">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="ciudadId"
                  id="ciudadId"
                  name="ciudadId"
                >
                  <option disabled value="">Ciudad</option>
                  <option
                    v-for="(ciudad, e) in ciudades"
                    :key="e"
                    :value="ciudad.id"
                  >
                    <p class="text-dark">
                      {{ ciudad.name }}
                    </p>
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="p-2">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="ubicacionSel"
                  id="ciudadId"
                  name="ciudadId"
                >
                  <option
                    v-for="(ubicacion, e) in ubicaciones"
                    :key="e"
                    :value="ubicacion._id"
                  >
                    <p class="text-dark">
                      {{ ubicacion.nombre }}
                    </p>
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <div class="p-2">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="areaId"
                  id="areaId"
                  name="areaId"
                >
                  <option v-for="(area, e) in areas" :key="e" :value="area._id">
                    <p class="text-dark">
                      {{ area.nombre }}
                    </p>
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="col-12">
              <div class="d-flex p-5" style="overflow-x: auto">
                <div class="mx-3 text-danger" v-if="areas.length === 0">
                  Aún no tienes areas en esta sede
                </div>
                <div
                  class="text-center mx-3 cursor-pointer"
                  v-for="(area, o) in areas"
                  :key="o"
                  @click="onAreaSel(area._id)"
                  style="width: 60px"
                >
                  <div style="text-align: -webkit-center">
                    <div
                      style="width: 45px; height: 45px"
                      class="bg-blue m-0 rounded-circle d-flex justify-content-center aign-items-center"
                    >
                      <i
                        style="font-size: 30px"
                        class="bi bi-geo-alt-fill p-3 text-white"
                      ></i>
                    </div>
                    <p class="mt-4" style="max-height: 60px; overfloy-y: auto">
                      {{ area.nombre }}
                    </p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-12">
      <div class="card p-5 h-100">
        <div class="d-block">
          <div class="d-flex mt-5 justify-content-evenly">
            <!-- <router-link
              to="equipos/crear"
              style="width: 64px; height: 64px"
              title="Crear equipo"
            >
              <div class="w-100 h-100 p-0 tex-center">
                <i
                  class="bi bi-plus-lg bg-blue rounded-circle py-3 px-4 fs-1 text-white fw-bold"
                ></i>
              </div>
            </router-link> -->
            <router-link
              to="areas/crear"
              style="width: 64px; height: 64px"
              title="Crear área"
            >
              <span class="w-100 h-100 p-0 tex-center">
                <i
                  class="bi bi-signpost-2 bg-blue rounded-circle py-3 px-4 fs-1 text-white fw-bold"
                ></i>
              </span>
            </router-link>
            <router-link
              :to="{ name: 'descargar-informe' }"
              style="width: 64px; height: 64px"
              title="Descargar informe"
            >
              <div class="w-100 h-100 p-0 tex-center">
                <i
                  class="bi bi-file-earmark-text bg-blue rounded-circle py-3 px-4 fs-1 text-white fw-bold"
                ></i>
              </div>
            </router-link>
          </div>
          <div class="d-flex align-items-center pt-5">
            <i class="bi bi-search me-3"></i>
            <input
              @keyup="searchQuery"
              type="text"
              placeholder="Ingresa el nombre del equipo"
              class="form-control"
              v-model="query"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <h2 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder text-blue">Listado Equipos</span>
  </h2>
  <div class="row h-75 overflow-auto">
    <div
      v-if="equipos.length === 0"
      class="card d-flex justify-content-center align-items-center"
    >
      Sin registros
    </div>
    <div
      v-for="(data, key) in equipos"
      :key="key"
      class="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
      style="height: 280px"
    >
      <!--begin::Mixed Widget 5-->
      <div class="card">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column">
          <div class="row pb-2 border-bottom align-items-center">
            <div class="col-10">
              <div class="d-flex my-auto align-items-center">
                <img
                  alt="Logo"
                  src="/media/logos/logo_sens.svg"
                  style="width: 30px"
                />
                <span class="card-label fs-3 fw-bolder m-1 text-blue">
                  {{ data.nombre }}
                </span>
                <span
                  @click="data.display = data.display ? false : true"
                  class="cursor-pointer"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                    />
                  </svg>
                </span>
                <div
                  class="card p-2"
                  style="position: absolute; right: 0px; z-index: 99; top: 50px"
                  v-show="data.display"
                  @click="data.display = data.display ? false : true"
                >
                  <div class="text-blue">
                    <div
                      @click="$refs.ModalAjuste.toggle(data, 1)"
                      class="row cursor-pointer"
                    >
                      <div class="col-12">
                        <i class="bi bi-arrows-move text-blue"></i>
                        <span class="mx-3">Mover</span>
                      </div>
                    </div>
                    <div
                      @click="$refs.ModalAjuste.toggle(data, 2)"
                      class="row cursor-pointer"
                    >
                      <div class="col-12">
                        <i class="bi bi-arrow-repeat text-blue"></i>
                        <span class="mx-3">Cambiar nombre</span>
                      </div>
                    </div>
                    <!-- <div
                      data-bs-toggle="modal"
                      data-bs-target="#modalEliminarDispositivo"
                      @click="dispositivoSelected = data._id"
                      class="row cursor-pointer"
                    >
                      <div class="col-12">
                        <i class="bi bi-trash text-blue"></i>
                        <span class="mx-3">Eliminar</span>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-end">
              <i
                @click="
                  $router.push({
                    name: 'profile-equipos-ver',
                    params: { id: data._id },
                  })
                "
                class="text-blue bi bi-arrow-right-circle fs-1 cursor-pointer"
              ></i>
            </div>

            <div class="col-12">
              <div class="text-muted card-toolbar">
                <el-tooltip placement="top">
                  <template #content>
                    {{ data.departamentoNombre }} → {{ data.ciudadNombre }} →
                    {{ data.sede.nombre }} →
                    {{ data.area.nombre }}
                  </template>
                  <span style="font-size: 11px" class="text-truncate d-block">
                    {{ data.departamentoNombreCorto }} →
                    {{ data.ciudadNombre }} → {{ data.sede.nombre }} →
                    {{ data.area.nombre }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div
              class="col-md-12 scroll-y"
              style="height: 110px; font-size: 11px"
            >
              <div class="row pb-2">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 text-green">
                    <img
                      src="@/assets/img/TermoVerde.png"
                      alt=""
                      style="height: 30px"
                    />
                    <span class="fw-bolder"> Temp. Interna </span>
                  </div>
                  <div>
                    <span class="fw-bolder"
                      >{{ data.sensorTempInternaValor || 0 }} °C</span
                    >
                  </div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 text-orange">
                    <img
                      src="@/assets/img/TermoNaranja.png"
                      alt=""
                      style="height: 30px"
                    />
                    <span class="fw-bolder"> Temp. Externa </span>
                  </div>
                  <div>
                    <span class="fw-bolder">
                      {{ data.sensorTempExternaValor || 0 }} °C
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-2">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 text-blue">
                    <i
                      class="bi bi-droplet-fill texto-valor text-blue fs-2"
                    ></i>
                    <span class="fw-bolder"> Humedad </span>
                  </div>
                  <div>
                    <span class="fw-bolder">
                      {{ data.sensorHumedadValor || 0 }} %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
    <!--end::Col-->
  </div>
  <!--end::Row-->
  <div class="row py-4">
    <div class="col-12 text-center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-model:current-page="currentPage"
        :page-sizes="[10, 20, 40, 60, 100]"
        :page-size="perPage"
        layout="sizes, prev, pager, next"
        :total="totalItem"
      >
      </el-pagination>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalEliminarDispositivo"
    tabindex="-1"
    aria-labelledby="modalEliminarDispositivoLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      style="border-radius: 38px; max-height: 321px; max-width: 375px"
    >
      <div class="modal-content p-5">
        <div class="text-center my-5">
          <h2 class="text-blue">¿Eliminar?</h2>
        </div>
        <div class="text-gray text-center my-5">
          <p>¿¡Está seguro de querer eliminar este dispositivo!?</p>
        </div>
        <div class="text-center my-5">
          <button
            @click="borrarDispositivo(dispositivoSelected)"
            class="btn bg-blue rounded-pill text-white"
            aria-label="Close"
            data-bs-dismiss="modal"
          >
            Confirmar
          </button>
        </div>
        <div class="text-center">
          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            class="btn text-blue rounded-pill"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>

  <ModalAjuste @responseSuccess="fetchDispositivos" ref="ModalAjuste" />
</template>

<script>
import { watch, ref, onMounted, defineComponent } from "vue";
import { useRouter } from "vue-router";
import sedes from "@/services/sedes.service";
import equiposService from "@/services/equipos.service";
import ModalAjuste from "@/components/modals/device/ajusteDispositivoModal.vue";
import { eliminarDispositivo } from "@/services/dispositivos";
import { alerta } from "@/utils/mensajes";

export default defineComponent({
  name: "MisEquiposPage",
  components: {
    ModalAjuste,
  },
  setup() {
    const router = useRouter();
    const currentPage = ref(1);
    const perPage = ref(10);
    const totalItem = ref(0);
    const query = ref("");
    const departamentos = ref([]);
    const dispositivoSelected = ref("");
    const departamentoId = ref("");
    const ciudadId = ref("");
    const ciudades = ref([]);
    const ubicaciones = ref([]);
    const traerDepartamentos = () => {
      sedes.listarDepartamentos("61a7b63262c066e408abe8c9").then((data) => {
        departamentos.value = data.data;
      });
    };
    const traerCiudades = (id) => {
      id = departamentoId.value;
      return sedes.listarCiudades(id).then((data) => {
        ubicaciones.value = [];
        areas.value = [];
        ciudades.value = data.data;
        return data.data;
      });
    };
    const traerSedes = (id) => {
      id = ciudadId.value;
      return sedes.listarSedesPorCiudades(id).then(({ data }) => {
        ubicaciones.value = data;
        areas.value = [];
        return data;
      });
    };
    const areas = ref([]);
    const ubicacionSel = ref(null);
    const areaId = ref(null);
    const traerAreas = (sedeId) => {
      return sedes
        .listarAreas(sedeId)
        .then(({ data }) => {
          areas.value = data.areas;
          return data.areas;
        })
        .catch((err) => {
          console.error(err.message);
        });
    };

    const fetchDispositivos = () => {
      const page = currentPage.value ? currentPage.value : 1;
      const rows = perPage.value ? perPage.value : 20;
      const idDepartamento = departamentoId.value ? departamentoId.value : "";
      const idCiudad = ciudadId.value ? ciudadId.value : "";
      const idSede = ubicacionSel.value ? ubicacionSel.value : "";
      const idArea = areaId.value ? areaId.value : "";
      const search = query.value ? query.value : "";
      equiposService
        .traerDispositivosPorAreas(
          page,
          rows,
          idDepartamento,
          idCiudad,
          idSede,
          idArea,
          search
        )
        .then(({ data }) => {
          equipos.value = data.data.map((item) => {
            switch (item.departamentoNombreCorto) {
              case "SAN":
                item.departamentoNombreCorto = "Stder.";
                break;
              case "NSA":
                item.departamentoNombreCorto = "Norte Stder.";
                break;
              case "CES":
                item.departamentoNombreCorto = "Cesar";
                break;
            }
            return {
              ...item,
            };
          });
          totalItem.value = data.total;
        })
        .catch((err) => {
          console.error(err.message);
        });
    };

    const equipos = ref([]);

    const botones = [
      {
        icon: "bi bi-plus-circle-fill",
        color: "text-blue",
        text: "Crear nuevo equipo",
        accion: "crearEquipo",
      },
      {
        icon: "bi bi-plus-circle-fill",
        color: "text-blue",
        text: "Agregar nuevo informe",
      },
    ];

    const ubicacion = (idUbica) => {
      console.log("ubicación: ", idUbica);
    };

    const accionbtn = (acc) => {
      console.log(acc);
      crearEquipo();
    };

    const crearEquipo = () => {
      router.push({ name: "profile-equipos-crear" });
    };

    const borrarDispositivo = (_id) => {
      eliminarDispositivo(_id).then((res) => {
        console.log(res);
        alerta(
          "Borrado",
          "Este dispositivo se ah borrado con exito",
          "success"
        );
        fetchDispositivos();
      });
    };

    const handleSizeChange = (val) => {
      perPage.value = val;
      fetchDispositivos();
    };
    const handleCurrentChange = (val) => {
      currentPage.value = val;
      fetchDispositivos();
    };

    const searchQuery = () => {
      fetchDispositivos();
    };

    onMounted(() => {
      traerDepartamentos();
      fetchDispositivos();
    });
    watch(
      () => departamentoId.value,
      (val) => {
        ciudadId.value = "";
        fetchDispositivos();
        if (val) {
          traerCiudades();
        }
      }
    );
    watch(
      () => ciudadId.value,
      (val) => {
        ubicacionSel.value = "";
        fetchDispositivos();
        if (val) {
          traerSedes();
        }
      }
    );
    watch(
      () => ubicacionSel.value,
      (sedeId) => {
        areaId.value = "";
        fetchDispositivos();
        if (sedeId) {
          traerAreas(sedeId);
        }
      }
    );
    watch(
      () => areaId.value,
      () => {
        fetchDispositivos();
      }
    );

    return {
      ubicacion,
      accionbtn,
      crearEquipo,
      fetchDispositivos,
      borrarDispositivo,
      botones,
      equipos,
      departamentos,
      ciudades,
      departamentoId,
      ciudadId,
      ubicaciones,
      ubicacionSel,
      areas,
      areaId,
      dispositivoSelected,
      currentPage,
      perPage,
      totalItem,
      query,
      handleSizeChange,
      handleCurrentChange,
      searchQuery,
    };
  },
});
</script>
