import ApiService from "@/core/services/ApiService";
export const getDispositivos = () => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/todos`
  );
};
export const verDispositivosId = (_id, queryParams) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/dispositivos/${_id}`,
    null,
    { params: queryParams }
  );
};
export const verDispositivosIdGrafica = (_id, queryParams) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/dispositivos/grafica/${_id}`,
    null,
    { params: queryParams }
  );
};
export const getDispositivosFiltrar = (queryParams = {}) => {
  return ApiService.query(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/filtrar`,
    { params: queryParams }
  );
};
export const crearDispositivos = (payload = {}) => {
  return ApiService.post(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}`,
    payload
  );
};
export const verSensor = (_id) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/sensores/${_id}`
  );
};
export const editarSensor = (_id, payload = {}) => {
  return ApiService.put(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/sensores/${_id}`,
    payload
  );
};
export const verNotificacion = (_dispositivoId) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/${_dispositivoId}/notificaciones`
  );
};
export const crearNotificacion = (_dispositivoId, payload = {}) => {
  return ApiService.post(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/${_dispositivoId}/notificaciones`,
    payload
  );
};
export const editarDispositivo = (_id, payload = {}) => {
  return ApiService.put(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/${_id}`,
    payload
  );
};
export const eliminarDispositivo = (_id) => {
  return ApiService.delete(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/dispositivos/${_id}`
  );
};

export default {
  getDispositivos,
  getDispositivosFiltrar,
  crearDispositivos,
  verDispositivosId,
  verNotificacion,
  crearNotificacion,
  verDispositivosIdGrafica,
  editarDispositivo,
  eliminarDispositivo,
};
